<template>
  <div>
  <b-card class="blog-edit-wrapper" :title="tituloForm">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- name -->
          <b-col cols="12">
            <b-form-group
                label-for="Nombre de rol"
            >
                <label >Nombre de rol <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="rol"
                  rules="required"
                  >
                  <b-form-input
                      id="rol"
                      v-model="name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Titulo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-for="error in errores.name" :key="error" class="text-danger">{{ error }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
            <b-col  md="6" cols="12">
              <div v-for="(permission, index) in permissions" :key="index">
                <div v-if="index == 0">
                    <hr>
                    <h6 class="text-uppercase">{{permission.tipo}}</h6>
                </div>
                <div v-if="index > 0 && permission.tipo!=permissions[index-1].tipo">
                    <hr>
                    <h6 class="text-uppercase">{{permission.tipo}}</h6>
                </div>
                <b-form-checkbox
                    v-model="form.permissions"  :value="permission.id"
                >
                    {{ permission.descripcion }} 
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col md="6" cols="12">
              <div v-for="(tipoEntrada, index) in tipoEntradas" :key="index">
                <div v-if="index == 0">
                  <hr>
                  <h6 class="text-uppercase">TIPOS DE ENTRA</h6>
                </div>
                <b-form-checkbox
                    v-model="form.tipoEntradas"  :value="tipoEntrada.id"
                >
                    {{ tipoEntrada.name }} 
                </b-form-checkbox>
              </div>
            </b-col>
        </b-row>
        <hr>
        <!-- submit -->
        <b-row class="my-1">
          <b-col cols="12" class="mt-50">
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="validar()"
            >
              Guardar
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'rol' }"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
  </div>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardText, BButton, BRow, BCol, BCardBody, BCardTitle, BCardSubTitle, BLink, BFormGroup, BFormInput, BForm, BFormTextarea, BImg, BFormFile, BDropdown, BDropdownItem, BContainer, BOverlay, BListGroupItem, BFormCheckbox 
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import { required, length } from '@validations';

import store from '@/store'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BImg, 
    BFormFile,
    BContainer,
    BOverlay,
    BDropdown, BDropdownItem,
    draggable,
    BListGroupItem,
    BFormCheckbox 
  },
  directives: {
    Ripple,
  },
  data () {
      return {
        //datos de entrada
        id:null,
        tipo:'',
        name:'',
        required,
        submitted: false,
        errores:[],
        permissions:[],
        form: {
            permissions: [],
            tipoEntradas: []
        },
        loading: false,
        tipoEntradas:[]
      }
  },

  computed: {
    tituloForm() {
      return this.id ? "Editar Rol" : "Nuevo Rol";
    },
  },
  watch: {
    $route: function(val) {
        if (val.params.id) {
          this.editar(this.$route.params.id);
        }else{
          this.limpiar();
        }
    },
  },
  created(){        
      this.permisos();
      this.listarTipoEntradas();
  },  
  destroyed() {
 
  },
  methods: {
    validar() {
        this.$refs.simpleRules.validate().then(success => {
            if (success) {
                if (!this.submitted) {
                  this.submitted = true;
                  if (this.id) {
                    this.actualizar();
                  }else{
                    this.registrar();
                  }
                }
            }
        })
    },

    //REGISTRO DE ROL
    registrar() {
      axiosIns.post('/admin/roles',{
          'name':this.name,
          'permissions':	this.form.permissions,
          'tipoEntradas':	this.form.tipoEntradas,
      })
      .then(res => {
        this.$bvToast.toast('Exito', {
          title: 'Rol de guardo con exito',
          variant:'primary',
          solid: false,
        })
        this.$router.push({ name: "rol" });
        this.errores=[];
        this.submitted = false;
        store.dispatch('auth/attempt',localStorage.getItem('token')).then(()=>{})
      })
      .catch(err =>{
          this.submitted = false;
          this.errores=err.response.data.errors;
      });
    },
    actualizar() {
      axiosIns.put('/admin/roles/'+this.id,{
        'name':this.name,
        'permissions':	this.form.permissions,
        'tipoEntradas':	this.form.tipoEntradas,
      })
      .then(res => {
        this.$bvToast.toast('Exito', {
          title: 'Rol de guardo con exito',
          variant:'primary',
          solid: false,
        })
        this.$router.push({ name: "rol" });
        this.errores=[];
        this.submitted = false;
        store.dispatch('auth/attempt',localStorage.getItem('token')).then(()=>{})
      })
      .catch(err =>{
          this.submitted = false;
          this.errores=err.response.data.errors;
      });
    },
    permisos(){
        var url= '/admin/permisos';
        axiosIns.get(url)
        .then(res => {
        var respuesta= res.data;
            this.permissions=respuesta.map(g => ({id:g.id, name: g.name, tipo: g.tipo, descripcion:g.descripcion}));
            })
            .catch(err =>{
                console.log(err);
            });
    },
    listarTipoEntradas() {
      var url = "/admin/tipo-entradas-permisos";
      axiosIns
        .get(url)
        .then((res) => {
          this.tipoEntradas = res.data.map((g) => ({
            name: g.nombre,
            id: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editar(id){
      this.id=id;
      axiosIns.get('/admin/roles/'+this.id)
      .then(res => {
        this.name=res.data.name;
        this.form.permissions=res.data.permissions.map(g => (g.id));
        this.form.tipoEntradas=res.data.tipo_entradas.map(g => (g.id));
      })
      .catch(err =>{

      });
    },
    limpiar(){
      this.id=null;
      this.name='';
      this.form.permissions=[];
      this.form.tipoEntradas=[];
    }
  },
  mounted(){
    if (this.$route.params.id) {
      this.editar(this.$route.params.id);
    }   
  }
}
</script>
<style lang="scss">
    hr.hr-text {
        position: relative;
        border: none;
        height: 1px;
        background: #ebe9f1;
    }

    hr.hr-text::before {
        content: attr(data-content);
        display: inline-block;
        background: #fff;
        font-weight: bold;
        font-size: 0.85rem;
        color: #999;
        border-radius: 30rem;
        padding: 0.2rem 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>